import React, { useMemo } from 'react'
import { StytchProvider } from '@stytch/nextjs'
import { ChordProvider as BaseChordProvider } from '@chordcommerce/react-autonomy'
import { StytchClient } from '@chordcommerce/chord-stytch'

import NotificationsContext from './notifications'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageProps: Record<string, any>
}

/**
 * ChordProvider is a context provider that provides Chord related
 * functionality.
 */
export const ChordProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  pageProps,
}) => {
  const stytchClient = new StytchClient({
    apiKey: process.env.NEXT_PUBLIC_STYTCH_API_KEY,
    redirectPath: '/authenticate',
    sessionDurationMinutes: 60,
  })
  const notifications = useMemo(
    () => pageProps.notificationBar ?? {},
    [pageProps.notificationBar]
  )
  /* tslint:disable */
  return (
    <BaseChordProvider
      config={{
        brandName: process.env.CHORD_OMS_BRAND_NAME,
        storeId: process.env.CHORD_OMS_STORE_ID,
        omsId: process.env.CHORD_OMS_ID,
        tenantId: process.env.CHORD_OMS_TENANT_ID,
        domain: process.env.CHORD_OMS_API_URL,
        storeSlug: process.env.CHORD_OMS_STORE_SLUG,
        currency: 'USD',
        locale: 'en-US',
      }}
      // @ts-ignore
      auth={stytchClient}
    >
      <NotificationsContext.Provider value={notifications}>
        <StytchProvider stytch={stytchClient.getServiceClient()}>
          {children}
        </StytchProvider>
      </NotificationsContext.Provider>
    </BaseChordProvider>
  )
}

export default ChordProvider
