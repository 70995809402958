import { getParam } from '../url'

export const setCookieFromParam = (pname: string) => {
  const param = getParam(pname)

  if (param) {
    document.cookie = `${pname}=${param}`
    return
  }

  return null
}

export const getCookie = (name: string) => {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=')
    return parts[0] === name ? decodeURIComponent(parts[1]) : r
  }, null)
}
