import { useContext, useMemo } from 'react'
import { useUser } from '@chordcommerce/react-autonomy'

import NotificationsContext from '~/context/notifications'
import { getParam } from '~/utils/url'
import type { INotificationBar } from 'graphql-cms/types'

interface UseNotificationBar {
  loaded: boolean
  notification: INotificationBar | null
}

const useNotificationBar = () => {
  const notificationBar = useContext(NotificationsContext)
  const { user } = useUser()
  const availableGiftCards = user?.data?.availableGiftCards
  const showRefNotification = !!getParam('ref')
  const notification = useMemo<UseNotificationBar['notification']>(() => {
    const defaultNotification = notificationBar

    if (showRefNotification) {
      return {
        name: 'Referral',
        slug: 'referral',
        content:
          "You'll automatically receive 15% off your first order for using your friend's referral link.",
        link: null,
      }
    }

    if (availableGiftCards?.length) {
      const giftCardAmount = availableGiftCards.reduce(
        (total, giftCard) => total + Number(giftCard.amount),
        0
      )

      return {
        name: 'Gift Card',
        slug: 'gift-card',
        content: `Hooray, you have a $${giftCardAmount} gift remaining! Shop now`,
        link: '/shop',
      }
    }

    return defaultNotification
  }, [availableGiftCards, showRefNotification, notificationBar])

  return {
    loaded: true,
    notification,
  }
}

export default useNotificationBar
