/** @jsxImportSource theme-ui */
import React from 'react'
import { Box } from 'theme-ui'
import styles from './styles.module.css'
import link_styles from '../Links/styles.module.css'

interface Props {
  showForm?: boolean
  mainFooter?: {
    name: string
    slug: string
    isALink: boolean
  } | null
}

const FooterNewsletterSignup: React.FC<Props> = ({ showForm = true, mainFooter }) => {
  return (
    <Box
      sx={{
        paddingBottom: ['2rem', '2.75rem', '0'],
        borderBottom: ['1px solid', null, 'none'],
        textAlign: ['center', null, 'left'],
        width: ['100%', null, '50%'],
      }}
    >
      {showForm && (
        <Box sx={{ width: ['100%', null, '100%'] }}>
          <div className={styles.klaviyo_form_cs}>
            <div className={`klaviyo-form-W3peWJ`}></div>
          </div>
        </Box>
      )}
      
      {mainFooter && (
        <div className={link_styles.mainFooterLink}>{mainFooter.name}</div>
      )}
    </Box>
  )
}

export default FooterNewsletterSignup
