import type { OrderBig, Adjustment } from '@chordcommerce/react-autonomy'
import { toUsdCurrency } from './../formatters'

type Order = OrderBig | Partial<OrderBig>

const eligibleOrderPromotion = (adjustment: Adjustment) => {
  return (
    adjustment.sourceType === 'Spree::PromotionAction' &&
    adjustment.adjustableType === 'Spree::Order' &&
    adjustment.eligible
  )
}

const eligibleLineItemPromotion = (adjustment: Adjustment) => {
  return (
    adjustment.sourceType === 'Spree::PromotionAction' &&
    adjustment.adjustableType === 'Spree::LineItem' &&
    adjustment.eligible
  )
}

const getOrderPromotions = (order: Order) => {
  if (!order.adjustments || order.adjustments.length === 0) return []

  return order.adjustments.filter((a) => eligibleOrderPromotion(a))
}

const getLineItemPromotions = (order: Order) => {
  if (!order.lineItems || order.lineItems.length === 0) return []

  return order.lineItems.reduce((acc, lineItem) => {
    const eligiblePromotions = lineItem.adjustments.filter((a) =>
      eligibleLineItemPromotion(a)
    )
    return acc.concat(eligiblePromotions)
  }, [] as Adjustment[])
}

const getAllCartPromotions = (order: Order) => {
  if (!order) return []

  const orderPromotions = getOrderPromotions(order)
  const lineItemPromotions = getLineItemPromotions(order)

  return [...orderPromotions, ...lineItemPromotions]
}

export interface CartPromotion {
  label: string
  amount: number
  displayAmount: string
  code: string | null
  id: string
}

export const getAllCartPromotionsForDisplay = (
  order: Order
): CartPromotion[] => {
  const promotions = getAllCartPromotions(order)

  const groupedPromos = promotions.reduce(
    (acc, { promotionCodeId, label, amount, promotionCode }) => {
      acc[promotionCodeId] = acc[promotionCodeId] || {
        label: label,
        amount: 0,
        code: promotionCode ? promotionCode.value : null,
      }
      acc[promotionCodeId].amount += parseFloat(amount)
      return acc
    },
    {} as Record<string | number, Omit<CartPromotion, 'displayAmount' | 'id'>>
  )

  return Object.entries(groupedPromos).map(
    ([key, { label, amount, code }]) => ({
      id: key,
      label: label,
      amount: amount,
      displayAmount: toUsdCurrency(amount),
      code: code ? code.toUpperCase() : null,
    })
  )
}
