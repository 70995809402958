/** @jsxImportSource theme-ui */
import React, { useState } from 'react'
import { Box, Container, Flex } from 'theme-ui'
import FooterConfig from '~/components/Footer/Links'
import FooterNewsletterSignup from '~/components/Footer/NewsletterSignup'
import Logo from '~/assets/images/logos/newLogoFooter.svg'
import { getMenu } from 'graphql-cms/queries/menu'
import { IMenu } from 'graphql-cms/types'

export const Footer: React.FC = () => {
  const [menuConfig, setMenuConfig] = useState<IMenu | null>(null)

  let footerFormated, mainFooterFormated
  if (!menuConfig) {
    getMenu().then(function(_menu: IMenu){
      setMenuConfig(_menu);
    })
  } else {
    const { footerConfigCollection, mainFooterCollection } = menuConfig
    footerFormated = footerConfigCollection?.items?.map((column, index) => {
      if (column.type[0] === 'Menu') {
        return (
          <FooterConfig key={index} title={column.title} links={column?.linksCollection?.items || []} />
        )
      } else if (column.type[0] === 'Custom') {
        return (
          <FooterNewsletterSignup key={index} />
        )
      }
    });

    mainFooterFormated = mainFooterCollection?.items?.map((column, index) => {
      if (index === 0) {
        return (
          <FooterNewsletterSignup key={index} showForm={false} mainFooter={column} />
        )
      } else {
        return (
          <FooterConfig key={index} mainFooter={column} />
        )
      }
    })
  }

  return (
    <Box as="footer" sx={{ paddingTop: '4.5rem' }}>
      <Container>
        {/* {footerFormated} */}
        <Flex sx={{ flexDirection: ['column', 'row'], paddingBottom: '115px', maxWidth: '168px', alignContent: ['flex-start', 'center'] }} >
          <Logo />
        </Flex>
        
        <Flex
          sx={{
            flexDirection: ['column', 'row'],
            paddingBottom: [null, '4.5rem'],
            justifyContent: 'end',
            maxWidth: '90%'
          }}
        >
          {footerFormated}
        </Flex>
      </Container>

      <Container>
        {mainFooterFormated && (
          <Flex
            sx={{
              justifyContent: 'end',
              maxWidth: '90%'
            }}
          >
            {mainFooterFormated}
          </Flex>
        )}
        
      </Container>
    </Box>
  )
}

export default Footer
