import React from 'react'
import { useCart } from '@chordcommerce/react-autonomy'
import NavBar from '~/components/Nav/Bar'

export const Nav: React.FC = () => {
  const { cart } = useCart()

  const itemCount =
    cart?.lineItems?.reduce?.((acc, item) => acc + item.quantity, 0) ?? 0

  return <NavBar itemCount={itemCount} />
}

export default Nav
