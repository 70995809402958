export const IMAGE_FRAGMENT = `
  __typename
  contentType
  sys {
    id
  }
  title
  url
  width
  height
`

export interface IImage {
  __typename: 'Asset'
  contentType: string
  sys: {
    id: string
  }
  title: string
  url: string
  width: number
  height: number
  placeholder?: string
}
