import { OPTION_VALUE_FRAGMENT, IOptionValue } from './option-value'

export const OPTION_TYPE_FRAGMENT = `
  name
  slug
  presentation
  optionValuesCollection(limit: 5) {
    items {
      ${OPTION_VALUE_FRAGMENT}
    }
  }
`

export interface IOptionType {
  name: string
  slug: string
  presentation: string
  optionValuesCollection: {
    items: IOptionValue[]
  }
}
