import { IMAGE_FRAGMENT, IImage } from './image'
import { PRODUCT_FRAGMENT, IProduct } from './product'
import { VARIANT_FRAGMENT, IVariant } from './variant'
import { RELATED_PRODUCT_FRAGMENT, IRelatedProduct } from './related-product'

export const BUNDLE_FRAGMENT = `
  __typename
  name
  slug
  masterSku
  description
  shortDescription
  price
  regularPrice
  itemQuantity
  soldOut
  comingSoon
  globalTradeItemNumber
  vendor
  productType {
    name
  }
  mainImage {
    ${IMAGE_FRAGMENT}
  }
  imagesCollection(limit: 3) {
    items {
      ${IMAGE_FRAGMENT}
    }
  }
  productsCollection(limit: 10) {
    items {
      __typename

      ...on Product {
        ${PRODUCT_FRAGMENT}
      }

      ...on Variant {
        ${VARIANT_FRAGMENT}
      }
    }
  }
  relatedProductsCollection(limit: 3) {
    items {
      ${RELATED_PRODUCT_FRAGMENT}
    }
  }
`

export interface IBundle {
  __typename: 'Bundle'
  name: string
  slug: string
  masterSku: string
  description: string | null
  shortDescription: string | null
  price: number
  regularPrice: number
  itemQuantity: number | null
  soldOut: boolean
  comingSoon: boolean
  globalTradeItemNumber: string | null
  vendor: string | null
  productType: {
    name: string
  } | null
  mainImage: IImage
  imagesCollection: {
    items: IImage[]
  }
  productsCollection: {
    items: (IProduct | IVariant)[]
  }
  relatedProductsCollection: {
    items: IRelatedProduct[]
  }
}
