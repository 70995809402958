/** @jsxImportSource theme-ui */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import type {
  IAnnouncementBar,
} from 'graphql-cms/types'
import { Link } from 'theme-ui'
import { calculateTimeLeft } from '~/utils'

interface Props {
  announcementBarConfig: IAnnouncementBar[] | false
}

const AnnouncementBar: React.FC<Props> = ({ announcementBarConfig }) => {
  const [activeMessage, setActiveMessage] = useState(null)
  const [firstLoad, setFirstLoad] = useState(false)
  const [hideBanner, setHideBanner] = useState(false)
  const [timeLeftToEndString, setTimeLeftToEndString] = useState<string | null>()

  const announcementBarCF = announcementBarConfig[0] || false
  if (!announcementBarCF) {
    return <></>
  }

  const announcements = (announcementBarCF?.announcementsCollection?.items || []).filter(function(annBarCF: { endDate: string }) {
    return (new Date(annBarCF.endDate).getTime() - new Date().getTime()) > 0
  });

  const initFunctionality = async (): Promise<void> => {
    if (!firstLoad) {
      let announcementCounter = 0
      let secondsInterval

      setInterval(() => {
        const newActive = announcements[announcementCounter]
        setActiveMessage(newActive);

        if (secondsInterval) {
          clearInterval(secondsInterval)
        }

        secondsInterval = setInterval(() => {
          const fNewEndDate = new Date(newActive?.endDate as string)
          const fTimeLeftEnd = calculateTimeLeft(fNewEndDate)
          setTimeLeftToEndString(fTimeLeftEnd)
        }, 300)

        const newEndDate = new Date(newActive?.endDate as string)
        const timeLeftEnd = calculateTimeLeft(newEndDate)
        setTimeLeftToEndString(timeLeftEnd)

        if (announcementCounter === (announcements.length - 1)) {
          announcementCounter = 0;
        } else {
          announcementCounter++;
        }
      }, (announcementBarCF.seconds * 1000))
      setFirstLoad(true);
    }
  }
  
  useEffect(() => {
    if (!firstLoad) {
      initFunctionality()
    }
  })

  if (hideBanner || !firstLoad || !timeLeftToEndString || !activeMessage) {
    return <></>
  }

  return ( !hideBanner &&
    <div style={{ display: 'block', width: '100%', textAlign: 'center' }}>
      <div className={styles.announcements_div}>
        <Link
          href={activeMessage.url}
          className={styles.announcements_link}
        >
          <span style={{ textTransform: 'uppercase' }}>{activeMessage.message || ''}</span>
          <span className={styles.announcements_link_separator}>&nbsp;&nbsp;&nbsp;&nbsp;_______&nbsp;&nbsp;&nbsp;&nbsp;</span>
          {timeLeftToEndString}
        </Link>
        <span onClick={() => setHideBanner(true)} className={styles.closeButton}></span>
      </div>
    </div>
  )
}

export default AnnouncementBar
