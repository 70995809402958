/** @jsxImportSource theme-ui */
import React, { useState } from 'react'
import { Box } from 'theme-ui'
import NavMenu from '~/components/Nav/Menu'
import NavMenuDesktop from '~/components/Nav/MenuDesktop'
import styles from './styles.module.css'
import { getMenu } from 'graphql-cms/queries/menu'
import { IMenu } from 'graphql-cms/types'

interface Props {
  itemCount?: number
}

export const NavBar: React.FC<Props> = ({ itemCount }) => {
  const [menuConfig, setMenuConfig] = useState<IMenu | null>(null)

  if (!menuConfig) {
    getMenu().then(function(_menu: IMenu){
      setMenuConfig(_menu);
    })
  } 

  return (
    <Box
    as="nav"
    role="navigation"
    className={styles.navBar}
    >
      <NavMenuDesktop {...menuConfig} itemCount={itemCount} />
      <NavMenu {...menuConfig} itemCount={itemCount} />
    </Box>
  )
}

export default NavBar
