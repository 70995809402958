/** @jsxImportSource theme-ui */
import React from 'react'
import { Container, Flex } from 'theme-ui'
// import { useTranslate } from '~/hooks'
import NavMenuDesktopLink from './Link'
// import CartLink from '../CartLink'
import NavMenuDesktopLogo from './Logo'
import { IMenu } from 'graphql-cms/types'

const NavMenuDesktop: React.FC<IMenu & { itemCount: number }> = (menuConfig) => {
// const NavMenuDesktop: React.FC<IMenu & { itemCount: number }> = (menuConfig, { itemCount }) => {
  // const translate = useTranslate()
  const pages =  (menuConfig?.headerLinksCollection?.items || [])
  const leftLinks = pages.filter((_page)=> _page.rightPosition === false)
  const rightLinks = pages.filter((_page)=> _page.rightPosition === true)

  return (
    <Container
      variant="fullWidth"
      sx={{
        alignItems: 'center',
        display: ['none', null, 'flex'],
        padding: '15px',
        width: '100%',
        transition: 'height .25s ease, box-shadow .25s ease',
        height: '70px',
        boxShadow: 'none',
      }}
    >
      <Flex sx={{ justifyContent: 'flex-start', width: '50%' }}>
        {leftLinks.map((page) => (
          <NavMenuDesktopLink page={page} key={page.slug} />
        ))}
      </Flex>

      <NavMenuDesktopLogo />

      <Flex sx={{ justifyContent: 'flex-end', width: '50%' }}>
        {rightLinks.map((page) => (
          <NavMenuDesktopLink page={page} key={page.slug} />
        ))}

        {/* <CartLink itemCount={itemCount}>
          {translate('navigation.cart')}{' '}
        </CartLink> */}

      </Flex>
    </Container>
  )
}

export default NavMenuDesktop
