import { IMAGE_FRAGMENT, IImage } from './image'
import { IScrollingBar, SCROLLING_BAR_FRAGMENT } from './scrolling-bar'
import type { IVariant } from './variant'

export const PAGE_FRAGMENT = `
  name
  slug
  hero {
    name
    heading
    subheading
    image {
      ${IMAGE_FRAGMENT}
    }
  }
  ${SCROLLING_BAR_FRAGMENT}
  sectionsCollection(limit: 3) {
    items {
      sys {
        id
      }
      title
      slug
      description
      mediaCollection(limit: 10) {
        items {
          __typename
          ... on Collection {
            sys {
              id
            }
            title
            slug
            description
            mainImage {
              ${IMAGE_FRAGMENT}
            }
          }
          ... on Product {
            sys {
              id
            }
            mainImage {
              ${IMAGE_FRAGMENT}
            }
            name
            slug
            shortDescription
            variantsCollection(limit: 5) {
              items {
                name
                sku
                globalTradeItemNumber
                price
                regularPrice
                size
                weight
                mainImage {
                  ${IMAGE_FRAGMENT}
                }
              }
            }
          }
          ... on Hero {
            name
            heading
            subheading
            image {
              ${IMAGE_FRAGMENT}
            }
            imageMobile {
              ${IMAGE_FRAGMENT}
            }
          }
        }
      }
      cta {
        json
      }
    }
  }
  withHeader
  withFooter
`

export interface ICollectionSection {
  __typename: 'Collection'
  sys: {
    id: string
  }
  title: string
  slug: string
  description: string
  mainImage: IImage
}

export interface IProductSection {
  __typename: 'Product'
  sys: {
    id: string
  }
  name: string
  slug: string
  shortDescription: string
  variantsCollection: {
    items: Omit<IVariant, 'optionValuesCollection' | 'imagesCollection'>[]
  }
}

export interface IHero {
  __typename: 'Hero'
  sys: {
    id: string
  }
  name: string
  heading: string
  subheading: string
  image: IImage
  imageMobile: IImage
}
export type IMediaSection = ICollectionSection | IProductSection | IHero
export interface ISection {
  sys: {
    id: string
  }
  title: string
  slug: string
  description: string
  mediaCollection: {
    items: IMediaSection[]
  }
  cta
}

export interface IPage {
  name: string
  slug: string
  hero: IHero
  scrollingBarCollection: {
    items: IScrollingBar[]
  }
  sectionsCollection: {
    items: ISection[]
  }
  withHeader: boolean
  withFooter: boolean
}
