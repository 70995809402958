import useSWR from 'swr'
import {
  useProduct,
  VariantStockAvailabilityInput,
} from '@chordcommerce/react-autonomy'

export default function useVariantAvailability(
  input: VariantStockAvailabilityInput
) {
  const { getStockAvailability } = useProduct()
  // useSWR is using an array for the key such that a SKU can be used in
  // multiple keys segemented by usage.
  const { data, error } = useSWR(input, (input) => getStockAvailability(input))

  // Start by assuming that the product is available. And if something
  // wrong happened in this hook, worse case scenario, it will be handled
  // by Chord's checkout.
  return {
    isAvailable: data ? data.inStock || data.isBackorderable : true,
    isFetchingAvailability: !error && !data,
    error: error,
  }
}
