import { IMAGE_FRAGMENT, IImage } from './image'
import { OPTION_VALUE_FRAGMENT, IOptionValue } from './option-value'

export const VARIANT_FRAGMENT = `
  sys {
    id
  }
  __typename
  name
  sku
  globalTradeItemNumber
  price
  regularPrice
  size
  weight
  optionValuesCollection(limit: 5) {
    items {
      ${OPTION_VALUE_FRAGMENT}
    }
  }
  mainImage {
    ${IMAGE_FRAGMENT}
  }
  imagesCollection(limit: 5) {
    items {
      ${IMAGE_FRAGMENT}
    }
  }
  linkedFrom {
    productCollection(limit: 1) {
      items {
        slug
      }
    }
  }
`

export interface IVariant {
  sys: {
    id: string
  }
  __typename: 'Variant'
  name: string
  sku: string
  globalTradeItemNumber: string
  price: number
  regularPrice: number
  size: string
  weight: number
  optionValuesCollection: {
    items: IOptionValue[]
  }
  mainImage: IImage
  imagesCollection: {
    items: IImage[]
  }
  linkedFrom: {
    productCollection: {
      items: {
        slug: string
      }[]
    }
  }
}
