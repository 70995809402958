/** @jsxImportSource theme-ui */
import React from 'react'
import { Button, ButtonProps } from 'theme-ui'

type Props = Omit<ButtonProps, 'onClick'> & {
  onClick: ButtonProps['onClick']
}

const NavMenuToggle: React.FC<Props> = (props) => {
  return (
    <Button
      key="menu"
      sx={(theme) => ({
        cursor: 'pointer',
        flexShrink: 0,
        variant: 'text.navLink',
        marginRight: '1rem',
        display: ['block', 'none'],
        background: 'transparent',
        padding: '0 .5em',
        fontSize: '1em',

        '&:focus, &:active, &:hover': {
          background: 'transparent',
          outline: `1px solid ${theme.colors.primary}`,
        },
      })}
      onClick={props.onClick}
      {...props}
    />
  )
}

export default NavMenuToggle
