/* eslint-disable @typescript-eslint/no-explicit-any */

export const objectCompare = (
  obj1: Record<string, any>,
  obj2: Record<string, any>
) => {
  const k1 = Object.keys(obj1)
  const k2 = Object.keys(obj2)
  if (k1.length !== k2.length) return false
  return k1.every((key) => {
    if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
      return objectCompare(obj1[key], obj2[key])
    }
    return obj1[key] === obj2[key]
  })
}

export const uniqueByKey = <T extends Record<string, any>>(
  key: keyof T | (keyof T)[],
  arr: T[]
): T[] => {
  const keys = Array.isArray(key) ? key : [key]
  return arr.filter(
    (v, i, a) =>
      a.findIndex((v2) => keys.every((key) => v2[key] === v[key])) === i
  )
}
