export const SUBSCRIPTION_FRAGMENT = `
  sys {
    id
  }
  name
  slug
  presentation
  discountPercentage
  prepaidSubscription
  maxInstallments
  intervalsCollection {
    items {
      sys {
        id
      }
      name
      slug
      presentation
      length
      unit
    }
  }
`

export interface ISubscription {
  sys: {
    id: string
  }
  name: string
  slug: string
  presentation: string
  discountPercentage: number | null
  prepaidSubscription: boolean | null
  maxInstallments: number | null
  intervalsCollection: {
    items: {
      sys: {
        id: string
      }
      name: string
      slug: string
      presentation: string
      length: number
      unit: 'day' | 'week' | 'month' | 'year'
    }[]
  }
}
