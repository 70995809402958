/** @jsxImportSource theme-ui */
import React from 'react'
import { Flex } from 'theme-ui'
import type { PropsOnlyChildren } from '~/types'

const NavMenuControls: React.FC<PropsOnlyChildren> = ({ children }) => {
  return <Flex sx={{ flexShrink: 0, alignItems: 'center' }}>{children}</Flex>
}

export default NavMenuControls
