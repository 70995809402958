import { IMAGE_FRAGMENT, IImage } from './image'
import type { IVariant } from './variant'

export const CATALOG_PRODUCT_FRAGMENT = `
  __typename
  name
  slug
  shortDescription
  vendor
  productType {
    name
  }
  giftCard
  sample
  hidden
  variantsCollection(limit: 15) {
    total
    items {
      name
      sku
      globalTradeItemNumber
      price
      regularPrice
      size
      weight
      optionValuesCollection(limit: 3) {
        items {
          name
          slug
          presentation
        }
      }
      mainImage {
        ${IMAGE_FRAGMENT}
      }
    }
  }
  mainImage {
    ${IMAGE_FRAGMENT}
  }
`

export interface ICatalogProduct {
  __typename: 'Product'
  name: string
  slug: string
  shortDescription: string
  vendor: string
  productType: {
    name: string
  } | null
  giftCard: boolean
  sample: boolean
  hidden: boolean
  variantsCollection: {
    total: number
    items: IVariant[]
  }
  mainImage: IImage
}

export const CATALOG_BUNDLE_FRAGMENT = `
  __typename
  name
  slug
  masterSku
  shortDescription
  price
  regularPrice
  itemQuantity
  soldOut
  comingSoon
  hidden
  globalTradeItemNumber
  vendor
  productType {
    name
  }
  mainImage {
    ${IMAGE_FRAGMENT}
  }
  productsCollection(limit: 10) {
    items {
      __typename

      ... on Product {
        vendor
        productType {
          name
        }
        giftCard
        sample
        hidden

        variantsCollection(limit: 3) {
          items {
            globalTradeItemNumber
            size
            weight
            optionValuesCollection(limit: 3) {
              items {
                name
                slug
                presentation
              }
            }
          }
        }
      }

      ... on Variant {
        globalTradeItemNumber
        size
        weight
        optionValuesCollection(limit: 3) {
          items {
            name
            slug
            presentation
          }
        }
      }
    }
  }
`

export interface ICatalogBundleProduct {
  __typename: 'Product'
  vendor: string | null
  productType: {
    name: string
  } | null
  giftCard: boolean
  sample: boolean
  hidden: boolean
  variantsCollection: {
    items: {
      globalTradeItemNumber: string
      size: string | null
      weight: number
      optionValuesCollection: {
        items: {
          name: string
          slug: string
          presentation: string
        }[]
      }
    }[]
  }
}

export interface ICatalogBundleVariant {
  __typename: 'Variant'
  globalTradeItemNumber: string
  size: string | null
  weight: number
  optionValuesCollection: {
    items: {
      name: string
      slug: string
      presentation: string
    }[]
  }
}

export interface ICatalogBundle {
  __typename: 'Bundle'
  name: string
  slug: string
  masterSku: string
  shortDescription: string | null
  price: number
  regularPrice: number
  itemQuantity: number | null
  soldOut: boolean
  comingSoon: boolean
  hidden: boolean
  globalTradeItemNumber: string | null
  vendor: string | null
  productType: {
    name: string
  } | null
  mainImage: IImage
  productsCollection: {
    items: (ICatalogBundleProduct | ICatalogBundleVariant)[]
  }
}

export const CATALOG_FRAGMENT = `
  sys {
    id
  }
  name
  slug
  collectionsCollection(limit: 20) {
    items {
      name
      slug
      title
      productsCollection(limit: 20) {
        items {
          __typename

          ... on Product {
            ${CATALOG_PRODUCT_FRAGMENT}
          }

          ... on Bundle {
            ${CATALOG_BUNDLE_FRAGMENT}
          }
        }
      }
    }
  }
`

export type ICatalogItem = ICatalogProduct | ICatalogBundle

export interface ICatalog {
  sys: {
    id: string
  }
  name: string
  slug: string
  collectionsCollection: {
    items: {
      name: string
      slug: string
      title: string
      productsCollection: {
        items: ICatalogItem[]
      }
    }[]
  }
}
