import React from 'react'
// import NotificationBar from '~/components/Notification/Bar'
import Nav from '~/components/Nav'
import Footer from '~/components/Footer'
import type { PropsOnlyChildren } from '~/types'
import AnnouncementBar from '../AnnouncementBar'

const Layout: React.FC<PropsOnlyChildren & {
  pageProps
}> = ({ children, pageProps }) => {
  return (
    <>
      {(pageProps?.page?.withHeader !== false) && (
        <>
          <AnnouncementBar announcementBarConfig={pageProps.announcementBarConfig || []} />
          <Nav />
        </>
      )}
      <main role="main">
        <>{children}</>
      </main>
      {(pageProps?.page?.withFooter !== false) && (
        <Footer />
      )}
    </>
  )
}

export default Layout
