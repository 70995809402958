/** @jsxImportSource theme-ui */
import React from 'react'
import Link, { LinkProps } from '~/components/Generic/Link'
import styles from './styles.module.css'
import classNames from 'classnames'

type Props = Omit<LinkProps, 'children' | 'href'> & {
  page: {
    name: string
    slug: string
    rightPosition: boolean
  }
}

const NavMenuDesktopLink: React.FC<Props> = ({ page }) => {
  const classes = page.rightPosition ? classNames(styles.links, styles.linkRight) : styles.links

  return (
    <Link
      href={`/${page.slug}/`}
      className={classes}
    >
      {page.name}
    </Link>
  )
}

export default NavMenuDesktopLink
