import {
  CATALOG_PRODUCT_FRAGMENT,
  CATALOG_BUNDLE_FRAGMENT,
  ICatalogItem,
} from './catalog'
import { IMAGE_FRAGMENT, IImage } from './image'

export const COLLECTION_FRAGMENT = `
  name
  slug
  title
  description
  mainImage {
    ${IMAGE_FRAGMENT}
  }
  productsCollection(limit: 50) {
    items {
      ... on Product {
        ${CATALOG_PRODUCT_FRAGMENT}
      }

      ... on Bundle {
        ${CATALOG_BUNDLE_FRAGMENT}
      }
    }
  }
`

export interface ICollection {
  name: string
  slug: string
  title: string
  description: string
  mainImage: IImage
  productsCollection: {
    items: ICatalogItem[]
  }
}
