export const OPTION_VALUE_FRAGMENT = `
  name
  slug
  presentation
  linkedFrom {
    optionTypeCollection(limit: 3) {
      items {
        slug
      }
    }
  }
`

export interface IOptionValue {
  name: string
  slug: string
  presentation: string
  linkedFrom: {
    optionTypeCollection: {
      items: {
        slug: string
      }[]
    }
  }
}
